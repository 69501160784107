.footer {
  position: sticky;
  top: 100vh;
  /*position: fixed;*/
  /*bottom: 0;*/
  width: 100%;
  /*height: 60px;   !* Height of the footer *!*/
  /*background: #6cf;*/

  flex-shrink: 0;
  background-color: #282727;
  padding: 20px 10px 40px;
  margin-top: 2rem;
}

.content {
  align-content: center;
  text-align: center;
  color: #ffffff;
}

.navItems {
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
}

.navItem {
  display: inline-block;
  margin-right: 1rem;
}

.socialMediaHolder {
  display: block;
  text-align: center;
  font-size: 0;
  margin-bottom: 25px;
}

.socialMediaLink {
  display: inline-block;
  border-radius: 50%;
  padding: 6px;
  line-height: 1em;
  text-decoration: none;
  margin: 0 6px;
  transition: all 0.5s ease;
  opacity: 0.6;
  color: #fff;
  font-size: 24px;
  height: 1em;
  overflow: visible;
}

.socialMediaIcon {
  color: #fff;
  font-size: 24px;
  height: 1em;
  overflow: visible;
  /*max-height: 24px;*/
}
